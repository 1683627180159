import {logger} from "@otto-ec/global-resources/debug";

const log = logger("ft3.promo-aggregation");

export class Aggregation {
    private static readonly APP_DASHBOARD_DRESON = "(und.(app).(~.(v.1)))";

    private static readonly SCROLL_INTO_VIEW_DELAY_MILLIS: number = 250;
    private static readonly SCROLL_CALC_DELAY: number = 150;

    private static aggregationContainer: HTMLElement;

    init(): Promise<void> {
        Aggregation.aggregationContainer = document.getElementById("promo-aggregation")!;
        Aggregation.overrideScrollRestorationIfSupported();
        const widgetSelector = this.getWidgetSelectorForRestoration();
        if (widgetSelector !== null) {
            Aggregation.blurScreen();
        } else {
            Aggregation.unblurScreen();
        }
        Aggregation.initAppSettings();
        return Aggregation.loadAllTheModules(document.getElementsByClassName("promo-module-container")[0]).then(() => Aggregation.restoreScrollPosition(widgetSelector));
    }

    private static loadAllTheModules(container: Element): Promise<void[]> {
        const allModules = container.querySelectorAll(".promo-module-hidden[data-module-url]");
        const ssid = container.getAttribute("data-ssid")!;
        return Promise.all(Aggregation.fetchModules(allModules, ssid));
    }

    private static fetchModules(allModules: NodeListOf<Element>, ssid: string): Promise<void>[] {
        /*                                                                   */
        if (!window.AbortController || !window.fetch) {
            log.debug('AbortController or fetch not supported by browser')
            return [Promise.resolve()]
        }

        /*         */
        return [].map.call(allModules, (module: Element) => {
            const url: string = module.getAttribute("data-module-url")!;
            const controller = new AbortController()
            const timeoutId = setTimeout(() => controller.abort(), 7500);
            /*                                                                                                         */
            const headers = new Headers({"X-Origin-Ssid-Aggregation": ssid, "X-Requested-With": "XMLHttpRequest"})
            return fetch(url, {
                headers: headers,
                method: 'GET',
                signal: controller.signal
            }).then(response => {
                if (response.status === 200) {
                    return response.text();
                } else {
                    throw new Error("promo-aggregation: Failed to load widget");
                }
            }).then((html: string) => {
                const docFragment = window.o_util.dom.stringToDocumentFragment(html);
                if (docFragment !== undefined && docFragment !== null) {
                    const asyncContainer = module.parentNode as HTMLElement;
                    if (asyncContainer) {
                        asyncContainer.replaceChild(docFragment, module);
                        if (asyncContainer.querySelectorAll("script").length > 0) {
                            log.debug("executeInlineScript for module:", asyncContainer.getAttribute("data-module-type"));
                            window.o_util.hardcore.executeInlineScripts(asyncContainer);
                        }
                        log.debug("invokePreload.processPreloadsOnElement for module:", asyncContainer.getAttribute("data-module-type"));
                        window.invokePreload.processPreloadsOnElement(asyncContainer);
                    }
                }
                clearTimeout(timeoutId)
            }).catch(() => {
                if (module.parentNode) {
                    module.parentNode.removeChild(module);
                }
                clearTimeout(timeoutId)
            })
        });
    }

    private static overrideScrollRestorationIfSupported() {
        if ('scrollRestoration' in window.history) {
            log.info("scrollRestoration is", window.history.scrollRestoration);
            if (window.history.scrollRestoration !== 'manual') {
                window.history.scrollRestoration = 'manual';
                log.info("scrollRestoration has been set to", window.history.scrollRestoration)
            }
            document.addEventListener("visibilitychange", () => {
                if (document.visibilityState === 'hidden') {
                    log.info("Page changes state to hidden.");
                    Aggregation.storeSelectorOrScrollPosition();
                }
            });
            window.addEventListener('scroll', () => Aggregation.handleScroll());
        }
    }

    private static storeSelectorOrScrollPosition() {
        const topWidgetSelector = Aggregation.findTopWidgetSelector();
        if (topWidgetSelector != null) {
            log.info("Storing widget selector for later back or forward navigation:", topWidgetSelector);
            const newState = Object.assign({}, window.history.state, {masLastWidgetSelector: topWidgetSelector, masLastScrollPosition: null});
            try {
                window.history.replaceState(newState, '');
            } catch (e) {
                log.warn("replace state failed", e)
            }
            /*                                                                               */
            try {
                window.sessionStorage.setItem("promo-aggregation-back-forward-selector-" + Aggregation.hashCode(location.href), topWidgetSelector);
            } catch (e) {
                /*                         */
            }
            log.info("promo-aggregation: window.history:", window.history);
        }
    }

    private static hashCode = (s: string) => s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0)

    private static findTopWidgetSelector(): string | null {
        const modules = document.querySelectorAll(".promo-module-foreground, .gridContainer > header, .promo_static-promotion-teaser-item, #nav-consultant, .shoppagesFragment, .sp_textBlock");
        for (let i = 0; i < modules.length; i++) {
            const module = modules.item(i);
            if (this.isElementInViewport(module)) {
                /*                                                                                             */
                /*                                                                                                */
                /*                                                                              */
                if (i + 1 < modules.length && module.contains(modules.item(i + 1))) {
                    continue;
                }
                /*                                                                                   */
                /*                                                 */
                if (Aggregation.isMoreThan40PercentOfElementVisible(module.getBoundingClientRect())) {
                    return Aggregation.createSelector(module);
                } else {
                    if (i + 1 < modules.length && this.isElementInViewport(modules.item(i + 1))) {
                        return Aggregation.createSelector(modules.item(i + 1));
                    } else {
                        return Aggregation.createSelector(module);
                    }
                }
            }
        }
        return null;
    }

    private static createSelector(elm: Element): string {

        /*                       */
        if (elm.id) {
            if (isNaN(parseInt(elm.id[0]))) {
                return `#${elm.id}`;
            } else {
                return `[id='${elm.id}']`;
            }
        }

        let element: Element = elm;
        const path = [];
        let parent;
        while (element.parentNode) {
            parent = element.parentNode;
            /*         */
            const tag = element.tagName
            let siblings: HTMLCollection;
            path.unshift(
                element.id ? `#${element.id}` : (
                    siblings = parent.children,
                        /*         */
                        [].filter.call(siblings, sibling => sibling.tagName === tag).length === 1 ? tag :
                            /*         */
                            `${tag}:nth-child(${1 + [].indexOf.call(siblings, element)})`
                )
            );
            /*         */
            element = parent;
        }
        return `${path.join(' > ')}`.toLowerCase();
    }

    private static isElementInViewport(el: Element) {
        const rect = el.getBoundingClientRect();
        return this.isElementEndingOrStartingInViewport(rect) ||
            this.doesElementOccupyTheWholeScreen(rect);
    }

    private static isMoreThan40PercentOfElementVisible(rect: DOMRect) {
        return (rect.top + rect.height) > (rect.height * 0.4);
    }

    private static isElementEndingOrStartingInViewport(rect: DOMRect) {
        return rect.top >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) ||
            rect.bottom >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    }

    private static doesElementOccupyTheWholeScreen(rect: DOMRect) {
        return rect.top < 0 && rect.bottom > (window.innerHeight || document.documentElement.clientHeight);
    }

    private static restoreScrollPosition(widgetSelector: string | null): Promise<void> {

        if (widgetSelector === null) {
            return Promise.resolve();
        }

        /*                                   */
        return new Promise(function (this: Aggregation, myResolve: () => void) {
            setTimeout(
                () => {
                    const lastSeenWidgetElement = document.querySelector(widgetSelector);
                    log.info("Scrolling to element with query selector:", widgetSelector);
                    Aggregation.unblurScreen();
                    lastSeenWidgetElement?.scrollIntoView();
                    const cleanState = {...window.history.state};
                    delete cleanState.masLastWidgetSelector;
                    delete cleanState.masLastScrollPosition;
                    try {
                        window.history.replaceState(cleanState, '');
                    } catch (e) {
                        log.warn("replace state failed", e)
                    }
                    myResolve();
                }, Aggregation.SCROLL_INTO_VIEW_DELAY_MILLIS)
        });
    }

    private getWidgetSelectorForRestoration(): string | null {
        let backOrForward = false;
        let perfEntries: PerformanceEntry[] = []
        try {
            /*                                     */
            perfEntries = performance.getEntriesByType('navigation');
        } catch (e) {
            /*                                  */
        }
        /*         */
        if (perfEntries.length && perfEntries[0].type === 'back_forward') {
            log.info("Found back_forward in performance entries with type navigation.");
            backOrForward = true;
        }
        log.info("window.history:", window.history);

        let fallbackSelector: string | null = null;
        try {
            fallbackSelector = window.sessionStorage.getItem("promo-aggregation-back-forward-selector-" + Aggregation.hashCode(location.href));
        } catch (e) {
            /*                        */
        }

        if (!backOrForward || ((!window.history.state || !window.history.state.masLastWidgetSelector) && !fallbackSelector)) {
            if (!window.history.state) {
                log.info("window.history.state is empty.");
            } else if (!window.history.state.masLastWidgetSelector) {
                log.info("window.history.state.masLastWidgetSelector is not set.");
            }

            if (!fallbackSelector) {
                log.info("no fallback selector in local storage.");
            }

            log.info("Restore scroll position handler detected no backward or forward navigation or no saved state to scroll to.");
            return null;
        }

        const widgetSelector = (window.history.state && window.history.state.masLastWidgetSelector) ? window.history.state.masLastWidgetSelector : fallbackSelector;
        if ((!window.history.state || !window.history.state.masLastWidgetSelector)) {
            log.info("Using fallback selector.");
        }
        return widgetSelector;
    }

    private static handleScroll() {
        const scrollY = window.scrollY;
        setTimeout(() => {
            if (scrollY === window.scrollY) {
                Aggregation.storeSelectorOrScrollPosition();
            }
        }, Aggregation.SCROLL_CALC_DELAY);
    }

    private static blurScreen(): void {
        /*                                     */
        Aggregation.aggregationContainer.classList.add("disabled-by-promo-aggregation")
    }

    private static unblurScreen(): void {
        /*                                     */
        Aggregation.aggregationContainer.classList.remove("disabled-by-promo-aggregation")
    }

    private static initAppSettings(): void {
        if (Aggregation.isApp()) {
            /*                                                                                                                                                                                     */
            document.body.style.backgroundColor = "#f0f0f0";

            if (Aggregation.aggregationContainer.getAttribute("data-dreson") === Aggregation.APP_DASHBOARD_DRESON) {
                /*                                   */
                window.o_global.eventQBus.emit("ft-apps.adjust.eventTracking", {
                        "eventName": {
                            "android": "3jiz37",
                            "ios": "td91zl"
                        },
                        "partnerParameters": {
                            "sitePath": "Homepage"
                        }
                    }
                );
            }
        }
    }

    private static isApp(): boolean {
        const appCookie = Aggregation.getCookie("app");
        return appCookie === "true";
    }

    /*                       */
    private static getCookie(name: string): string | null {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop()?.split(';').shift() || null;
        }
        return null;
    }
}
